<template>
  <div class="details-mian" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 头部 -->
    <public-header></public-header>
    <!-- 主体 -->
    <div class="page_bd" id="pdfDom">
      <!-- 头部公司介绍 -->
      <div class="cen-mian-head">
        <!-- 介绍左侧 -->
        <div class="cen-mian-head-l">
          <!-- 头部公司 -->
          <div class="cen-mian-head-lcompany">
            <span>{{company.name}}</span>
            <div
              v-for="(bonusitem,bonusIndex) in bonusFilter(detailsInfo.bonus)"
              :class="'company-tag'+' company-tag_'+bonusitem.ID"
              :key="bonusIndex"
            >{{bonusitem.name}}</div>
          </div>
          <!-- //地址 -->
          <div>
            <span>地址：{{company.region_province}}{{company.region_city}}{{company.region_county}}{{company.region_details}}</span>
          </div>
          <!-- 联系方式 -->
          <div class="cen-l-contact-details">
            <div>
              <div>{{$t('电话')}}：{{company.phone}}</div>
              <div>{{$t('电子邮件')}}：{{company.email}}</div>
            </div>
            <div>
              <div>{{$t('传真')}}：{{company.fax_number}}</div>
              <div>公司官网：{{company.website}}</div>
            </div>
          </div>
        </div>
        <!-- 介绍右侧 -->
        <div>
          <div
            v-if="detailsInfo.collection_group_id && detailsInfo.collection_group_id.length > 0"
            class="collect-btn collect-btn-on"
            @click="clickCollectionManagement()"
          >
            <div>
              <i class="el-icon-star-on"></i>
            </div>
            <div>收藏管理</div>
          </div>
          <div v-else class="collect-btn collect-btn-off" @click="clickCollectionManagement()">
            <div>
              <i class="el-icon-star-off"></i>
            </div>
            <div>点击收藏</div>
          </div>
        </div>
      </div>
      <div class="details-main-content">
        <!-- 按钮组 -->
        <div class="details-main-content-menu">
          <div
            v-for="(item,index) in tabList"
            :key="index"
            @click="clickTab(item.value)"
            :class="[currentTab==item.value?'active':'']"
          >{{item.name}}</div>
        </div>
        <!-- 基本信息 -->
        <div ref="basicInformation" class="details-main-content-part">
          <info-container title="基本信息">
            <info-item label="统一编号" :labelWidth="120">{{company.uniform_numbers}}</info-item>
            <info-item :label="$t('代表人姓名')">{{company.representative_name}}</info-item>
            <info-item :label="$t('公司状况')" :labelWidth="140">{{company.status}}</info-item>
            <div class="divider-line"></div>
            <info-item :label="$t('核准建设日期')" :labelWidth="120">{{company.date_of_establishment}}</info-item>
            <info-item label="资本总额">{{company.total_capital|formatNumber}}</info-item>
            <info-item
              :label="$t('实收资本(新台币)')"
              :labelWidth="140"
            >{{company.paid_in_capital|formatNumber}}</info-item>
            <div class="divider-line"></div>
            <info-item label="营业项目" :labelWidth="120" :width="1000">{{company.business_item}}</info-item>
            <div class="divider-line"></div>
          </info-container>
        </div>
        <!-- 上市信息 -->
        <div ref="listingInformation" class="details-main-content-part">
          <info-container title="上市信息">
            <info-item :label="$t('公司简称')">{{company.abbreviation}}</info-item>
            <info-item :label="$t('股票代号')">{{company.stock_code}}</info-item>
            <info-item label="上市日期">{{company.listing_date}}</info-item>
            <div class="divider-line"></div>
            <info-item :label="$t('董事长')">{{company.chairman_of_the_board}}</info-item>
            <info-item :label="$t('总经理')">{{company.general_manager}}</info-item>
            <info-item :label="$t('发言人')">{{company.spokesman}}</info-item>
            <div class="divider-line"></div>
            <info-item :label="$t('产业类别')" :width="1000">{{company.industry_category}}</info-item>
            <div class="divider-line"></div>
            <info-item :label="$t('主营业务')" :width="1000">{{company.main_business}}</info-item>
            <div class="divider-line"></div>
          </info-container>
        </div>
        <!-- 产品信息 -->
        <div ref="productInformation" class="details-main-content-part">
          <info-container :title="$t('产品信息')">
            <Product :detailsInfo="detailsInfo.products" :displayInfo="detailsInfo.products"></Product>
          </info-container>
        </div>

        <!-- 投资联络 -->
        <div ref="investment" class="details-main-content-part">
          <info-container :title="$t('投资联络')">
            <info-item :label="$t('联络人姓名')">{{company.contact_person}}</info-item>
            <info-item :label="$t('联络人职称')">{{company.contact_person_title}}</info-item>
            <div class="divider-line"></div>
            <info-item :label="$t('联络人电话')">{{company.contact_person_phone}}</info-item>
            <info-item :label="$t('联络人邮箱')">{{company.contact_person_email}}</info-item>
            <div class="divider-line"></div>
          </info-container>
        </div>
        <!-- 奖补信息 -->
        <div ref="prizeInformation" class="details-main-content-part">
          <info-container :title="$t('奖补信息')">
            <div
              class="prize-haed-head-items"
              v-for="(item,index) in detailsInfo.bonus"
              :key="item.name+index"
            >
              <div>
                <span>{{$t('获得由中国台湾地区')}}</span>
                <span class="department">{{item.issuing_department}}</span>
                <span>{{$t('颁发的')}}{{item.award_year}}</span>
                <span
                  :class="'company-tag'+' company-tag_'+item.ID"
                  @click="goSearchPrize(item.ID)"
                >{{item.name}}</span>
              </div>
              <div class="prize-reason">{{$t('获奖项目')}}：{{item.reason_for_winning}}</div>
            </div>
          </info-container>
        </div>
        <!-- 营收资讯 -->
        <div ref="revenueInformation" class="details-main-content-part">
          <info-container :title="$t('营收资讯')">
            <div slot="subTitle">{{$t('单位：新台币千元')}}</div>
            <div
              class="revenue-cen-table"
              v-if="detailsInfo.revenue!= '' && detailsInfo.revenue!=null"
            >
              <div>
                <div>
                  <div>{{$t('本年至今累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].year_to_date_cumulative_revenue}}</div>
                </div>
                <div>
                  <div>{{detailsInfo.revenue[0].one_month_ago_title}}</div>
                  <div>{{detailsInfo.revenue[0].one_month_ago_revenue}}</div>
                </div>
                <div>
                  <div>{{detailsInfo.revenue[0].two_month_ago_title}}</div>
                  <div>{{detailsInfo.revenue[0].two_month_ago_revenue}}</div>
                </div>
                <div>
                  <div>{{detailsInfo.revenue[0].three_month_ago_title}}</div>
                  <div>{{detailsInfo.revenue[0].three_month_ago_revenue}}</div>
                </div>
                <div>
                  <div>{{detailsInfo.revenue[0].four_month_ago_title}}</div>
                  <div>{{detailsInfo.revenue[0].four_month_ago_revenue}}</div>
                </div>
              </div>
              <div>
                <div>
                  <div>{{$t('去年同期累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].cumulative_revenue_in_the_same_period_last_year}}</div>
                </div>
                <div>
                  <div>{{$t('去年同期月营收')}}</div>
                  <div>{{detailsInfo.revenue[0].one_month_ago_revenue_in_the_same_period_last_year}}</div>
                </div>
                <div>
                  <div>{{$t('去年同期月营收')}}</div>
                  <div>{{detailsInfo.revenue[0].two_month_ago_revenue_in_the_same_period_last_year}}</div>
                </div>
                <div>
                  <div>{{$t('去年同期月营收')}}</div>
                  <div>{{detailsInfo.revenue[0].three_month_ago_revenue_in_the_same_period_last_year}}</div>
                </div>
                <div>
                  <div>{{$t('去年同期月营收')}}</div>
                  <div>{{detailsInfo.revenue[0].four_month_ago_revenue_in_the_same_period_last_year}}</div>
                </div>
              </div>
              <div>
                <div>
                  <div>{{$t('累计营收成长率')}}</div>
                  <div>{{detailsInfo.revenue[0].cumulative_revenue_growth_rate}}</div>
                </div>
                <div>
                  <div>{{$t('本年至今累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].one_month_ago_revenue_growth_rate}}</div>
                </div>
                <div>
                  <div>{{$t('本年至今累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].two_month_ago_revenue_growth_rate}}</div>
                </div>
                <div>
                  <div>{{$t('本年至今累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].three_month_ago_revenue_growth_rate}}</div>
                </div>
                <div>
                  <div>{{$t('本年至今累计营收')}}</div>
                  <div>{{detailsInfo.revenue[0].four_month_ago_revenue_growth_rate}}</div>
                </div>
              </div>
            </div>
            <div class="table-emy" v-else>
              <div>{{$t('暂时没有相关数据')}}！</div>
            </div>
          </info-container>
        </div>
        <!-- 财务资讯 -->
        <div ref="financialInformation" class="details-main-content-part">
          <info-container :title="$t('财务资讯')">
            <div slot="subTitle">{{$t('单位：新台币千元')}}</div>
            <!-- 财报表格 -->
            <div
              class="financial-report-table"
              v-if="detailsInfo.concise_cash_flow!= '' && detailsInfo.concise_cash_flow!=null"
            >
              <!-- 头部 -->
              <div class="financial-report-table-head">
                <div>{{$t('期别')}}</div>
                <div>{{detailsInfo.concise_cash_flow[2]?detailsInfo.concise_cash_flow[2].year:'-'}}</div>
                <div>{{detailsInfo.concise_cash_flow[1]?detailsInfo.concise_cash_flow[1].year:'-'}}</div>
                <div>{{detailsInfo.concise_cash_flow[0]?detailsInfo.concise_cash_flow[0].year:'-'}}</div>
              </div>
              <!-- 底部内容 -->
              <div class="financial-report-table-cen">
                <div class="table-cen-l" style="margin-top: 0.0625rem;">
                  <div>{{$t('简明资产负债')}}</div>
                  <div>{{$t('简明综合损益表')}}</div>
                  <div>{{$t('简明现金流量表')}}</div>
                </div>
                <div class="table-cen-r">
                  <div>
                    <div>{{$t('资产总计')}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[2]?detailsInfo.concise_assets_and_liabilities[2].total_assets:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[1]?detailsInfo.concise_assets_and_liabilities[1].total_assets:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[0]?detailsInfo.concise_assets_and_liabilities[0].total_assets:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('负债总计')}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[2]?detailsInfo.concise_assets_and_liabilities[2].total_liabilities:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[1]?detailsInfo.concise_assets_and_liabilities[1].total_liabilities:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[0]?detailsInfo.concise_assets_and_liabilities[0].total_liabilities:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('权益总计')}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[2]?detailsInfo.concise_assets_and_liabilities[2].total_equity:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[1]?detailsInfo.concise_assets_and_liabilities[1].total_equity:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[0]?detailsInfo.concise_assets_and_liabilities[0].total_equity:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('每股净值(元)')}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[2]?detailsInfo.concise_assets_and_liabilities[2].net_value_per_share:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[1]?detailsInfo.concise_assets_and_liabilities[1].net_value_per_share:'-'}}</div>
                    <div>{{detailsInfo.concise_assets_and_liabilities[0]?detailsInfo.concise_assets_and_liabilities[0].net_value_per_share:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('营业收入')}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[2]?detailsInfo.concise_comprehensive_profit_and_loss[2].operating_income:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[1]?detailsInfo.concise_comprehensive_profit_and_loss[1].operating_income:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[0]?detailsInfo.concise_comprehensive_profit_and_loss[0].operating_income:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('营业利益')}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[2]?detailsInfo.concise_comprehensive_profit_and_loss[2].operating_profit:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[1]?detailsInfo.concise_comprehensive_profit_and_loss[1].operating_profit:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[0]?detailsInfo.concise_comprehensive_profit_and_loss[0].operating_profit:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('税前净利')}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[2]?detailsInfo.concise_comprehensive_profit_and_loss[2].net_profit_before_tax:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[1]?detailsInfo.concise_comprehensive_profit_and_loss[1].net_profit_before_tax:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[0]?detailsInfo.concise_comprehensive_profit_and_loss[0].net_profit_before_tax:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('基本每股盈余')}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[2]?detailsInfo.concise_comprehensive_profit_and_loss[2].basic_earnings_per_share:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[1]?detailsInfo.concise_comprehensive_profit_and_loss[1].basic_earnings_per_share:'-'}}</div>
                    <div>{{detailsInfo.concise_comprehensive_profit_and_loss[0]?detailsInfo.concise_comprehensive_profit_and_loss[0].basic_earnings_per_share:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('营业活动之净现金流入')}}</div>
                    <div>{{detailsInfo.concise_cash_flow[2]?detailsInfo.concise_cash_flow[2].net_cash_inflow_outflow_from_operating_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[1]?detailsInfo.concise_cash_flow[1].net_cash_inflow_outflow_from_operating_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[0]?detailsInfo.concise_cash_flow[0].net_cash_inflow_outflow_from_operating_activities:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('投资活动之净现金流入')}}</div>
                    <div>{{detailsInfo.concise_cash_flow[2]?detailsInfo.concise_cash_flow[2].net_cash_inflow_outflow_from_investing_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[1]?detailsInfo.concise_cash_flow[1].net_cash_inflow_outflow_from_investing_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[0]?detailsInfo.concise_cash_flow[0].net_cash_inflow_outflow_from_investing_activities:'-'}}</div>
                  </div>
                  <div>
                    <div>{{$t('筹资活动之净现金流入')}}</div>
                    <div>{{detailsInfo.concise_cash_flow[2]?detailsInfo.concise_cash_flow[2].net_cash_inflow_outflow_from_financing_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[1]?detailsInfo.concise_cash_flow[1].net_cash_inflow_outflow_from_financing_activities:'-'}}</div>
                    <div>{{detailsInfo.concise_cash_flow[0]?detailsInfo.concise_cash_flow[0].net_cash_inflow_outflow_from_financing_activities:'-'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-emy" v-else>
              <div>{{$t('暂时没有相关数据')}}！</div>
            </div>
          </info-container>
        </div>
        <!-- <div ref="patentInformation" class="details-main-content-part">
          <info-container title="专利信息">
            <img class="patent-img" src="@/assets/images/detail/zhuanli.jpg" />
          </info-container>
        </div>-->
      </div>
    </div>

    <favoritesAdd :visible.sync="collectVisible" :companyId="id" @changeSuccess="changeSuccess"></favoritesAdd>
    <!-- 回到顶部 -->
    <back-top></back-top>
    <public-footer></public-footer>
  </div>
</template>

<script>
//组件引入
import Product from '../../components/details/product.vue'
import favoritesAdd from '../../components/modeOpen/favorites_add.vue'
import infoContainer from '../../components/details/infoContainer.vue'
import infoItem from '../../components/details/infoItem.vue'
export default {
  data() {
    return {
      id: '', //公司id
      detailsInfo: [], //公司基本信息
      currentTab: 'basicInformation',

      collectVisible: false,
      fullscreenLoading: false,
    }
  },
  computed: {
    company() {
      return this.detailsInfo.company || {}
    },
    // tab按钮组
    tabList() {
      let result = [
        {
          name: '基本信息',
          value: 'basicInformation',
        },
        {
          name: '上市信息',
          value: 'listingInformation',
        },
        {
          name: this.$t('产品信息'),
          value: 'productInformation',
        },
        {
          name: this.$t('投资联络'),
          value: 'investment',
        },
        {
          name: this.$t('奖补信息'),
          value: 'prizeInformation',
        },
        {
          name: this.$t('营收资讯'),
          value: 'revenueInformation',
        },
        {
          name: '财报资讯',
          value: 'financialInformation',
        },
        // {
        //   name: '专利信息',
        //   value: 'patentInformation',
        // },
      ]
      return result
    },
  },
  methods: {
    init() {
      this.detailsList()
    },
    // 标签去重
    bonusFilter(val) {
      let data = []
      if (val) {
        val.map((item) => {
          if (!data.find((o) => o.ID === item.ID)) {
            data.push(item)
          }
        })
      }
      return data
    },
    debounce(fn, delay) {
      let timer = null
      return function () {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(fn, delay)
      }
    },
    // 页面加载
    detailsList() {
      this.fullscreenLoading = true
      let params = {
        id: this.id,
      }
      this.$service
        .getCompanyDetails(params)
        .then((res) => {
          this.detailsInfo = res
          this.fullscreenLoading = false
          setTimeout(() => {
            this.getContentBlockOffsetTop()
            this.scrollInit()
            window.addEventListener('scroll', this.debounce(this.onScroll, 300))
          }, 2000)
        })
        .catch(() => {
          this.fullscreenLoading = false
        })
    },
    // 切换锚点
    clickTab(e) {
      this.currentTab = e
      document.documentElement.scrollTop = this.tabListScollTop[e].offsetTop
    },
    // 点击收藏管理
    clickCollectionManagement() {
      this.collectVisible = true
    },
    // 收藏成功回调
    changeSuccess(val) {
      this.detailsInfo.collection_group_id = val.let
    },
    // 搜索相关奖项
    goSearchPrize(e) {
      const { href } = this.$router.resolve({
        path: '/search',
        query: {
          labelId: e,
        },
      })
      window.open(href, '_blank')
    },
    // 获取各个模块到顶部的距离
    getContentBlockOffsetTop() {
      this.tabListScollTop = {}
      this.tabList.map((i) => {
        let offsetTop = this.$refs[i.value].offsetTop - 90
        let offsetHeight = this.$refs[i.value].offsetHeight
        this.tabListScollTop[i.value] = {
          offsetTop: offsetTop,
          offsetBottom: offsetTop + offsetHeight,
        }
      })
    },
    // 产品信息跳转初始化
    scrollInit() {
      if (this.$route.query.product == 'true') {
        this.$refs['productInformation'].scrollIntoView()
      }
    },
    // 滚动条监听
    onScroll() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      for (let i in this.tabListScollTop) {
        if (scrollTop + 100 < this.tabListScollTop[i].offsetBottom) {
          this.currentTab = i
          break
        }
      }
    },
  },
  mounted() {
    this.id = Number(this.$route.query.id)
    this.init()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounce(this.onScroll, 300))
  },
  components: {
    Product,
    favoritesAdd,
    infoContainer,
    infoItem,
  },
}
</script>

<style lang="scss" scope>
.table-emy {
  height: 7.3125rem;
  margin: 0rem 1rem;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  background: #fafdff;
  color: #999999;
  border: 1px solid #9accfe;
}

/* 财务资讯 */
.financial-report {
  display: flex;
  flex-flow: column;
  padding: 1.25rem 0.875rem;
}

.financial-report-head {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 1px solid #f6f6f6;
  height: 3.125rem;
  align-items: center;
  margin-bottom: 0.9375rem;
}

.financial-report-head > div:first-of-type > span:first-of-type {
  height: 1rem;
  border-left: 4px solid #3e68ff;
  margin-right: 0.625rem;
}

/* 财务表格 */
.financial-report-table {
  display: flex;
  flex-flow: column;
}

.financial-report-table-head {
  display: flex;
  flex-flow: row;
  height: 2rem;
}

.financial-report-table-head > div {
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  background: #dbe8ff;
  margin-right: 0.0625rem;
}

.financial-report-table-head > div:first-of-type {
  flex: none;
  width: 28.9375rem;
}

.financial-report-table-cen {
  display: flex;
  flex-flow: row;
  color: #666666;
}

.table-cen-l {
  width: 9.75rem;
  display: flex;
  flex-flow: column;
}

.table-cen-l > div {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: #dbe8ff;
  margin-bottom: 0.0625rem;
}

.table-cen-l > div:last-of-type {
  flex: none;
  height: 6.125rem;
}

.table-cen-r {
  display: flex;
  flex-flow: column;
  flex: 1;
}

.table-cen-r > div {
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
}

.table-cen-r > div > div {
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 2rem;
  background: #f4f6f8;
  margin-right: 0.0625rem;
  margin-bottom: 0.0625rem;
}

.table-cen-r > div > div:first-of-type {
  justify-content: flex-start;
  flex: none;
  width: 19.1875rem;
  padding-left: 0.375rem;
}

/* 营收资讯 */
.revenue-cen {
  display: flex;
  flex-flow: column;
  padding: 1.25rem 0.875rem;
}

.revenue-cen-head {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: 1px solid #f6f6f6;
  height: 3.125rem;
  align-items: center;
  margin-bottom: 0.9375rem;
}

.revenue-cen-head > div:first-of-type > span:first-of-type {
  height: 1rem;
  border-left: 4px solid #3e68ff;
  margin-right: 0.625rem;
}

.revenue-cen-table {
  display: flex;
  flex-flow: column;
}

.revenue-cen-table {
  display: flex;
  flex-flow: row;
}

.revenue-cen-table > div {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.revenue-cen-table > div > div {
  display: flex;
  flex-flow: column;
}

.revenue-cen-table > div > div > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

.revenue-cen-table > div > div > div:nth-child(odd) {
  background: #dbe8ff;
  color: #666666;
}

.revenue-cen-table > div > div > div:nth-child(even) {
  background: #f4f6f8;
  color: #333333;
}

/* 投资联络 */
.investment {
  display: flex;
  flex-flow: column;
  padding: 1.875rem 1.25rem;
}

.investment-haed {
  display: flex;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 1rem;
  font-size: 1.125rem;
}

.investment-haed > span:first-of-type {
  height: 1rem;
  border-left: 4px solid #3e68ff;
  margin-right: 0.625rem;
}

.investment-haed-items {
  border-bottom: 1px solid #f6f6f6;
  padding: 12px 0 7px 0;
  color: #333333;
  & > div {
    width: 400px;
  }
  div {
    display: inline-block;
  }
  .label {
    height: 16px;
    width: 96px;
    margin-right: 10px;
    line-height: 16px;
    color: #666666;
    border-right: 2px solid #3e68ff;
  }
}

/* 奖补信息 */
.prize-list {
  display: flex;
  flex-flow: column;
  padding: 1.875rem 0.75rem 1.875rem 1.25rem;
}

.prize-haed-head {
  display: flex;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 1rem;
  font-size: 1.125rem;
}

.prize-haed-head > span:first-of-type {
  height: 1rem;
  border-left: 4px solid #3e68ff;
  margin-right: 0.625rem;
}

.prize-haed-head-items {
  position: relative;
  padding: 14px 14px 8px 14px;
  border-bottom: 1px solid #f6f6f6;
  line-height: 30px;
  color: #666;
  font-size: 16px;
  &::before {
    position: absolute;
    left: 0;
    top: 26px;
    width: 4px;
    height: 4px;
    background: #333333;
    border-radius: 50%;
    content: '';
  }
  .department {
    color: #3e68ff;
  }
  .prize-reason {
    color: #999999;
  }
  .company-tag {
    cursor: pointer;
  }
}

.details-main-content {
  display: flex;
  flex-flow: column;
  background: #ffffff;
  margin-top: 20px;

  &-menu {
    position: sticky;
    top: 64px;
    display: flex;
    background-color: #f4f6f8;
    border-bottom: 1px solid #3e68ff;
    z-index: 100;

    & > div {
      height: 36px;
      padding: 0 18px;
      margin-right: 1px;
      line-height: 36px;
      text-align: center;
      color: #333333;
      background-color: #fff;
      cursor: pointer;
      font-size: 18px;
    }

    .active {
      font-size: 1.25rem;
      background: #3e68ff;
      color: #ffffff;
      box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.16);
    }
  }
}

.details-mian {
  display: flex;
  flex-flow: column;
  background: F4F6F8;
  background: #f4f6f8;
}
.cen-mian-head {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #f6f6f6;
  padding-left: 0.9375rem;
  padding-right: 2.125rem;
  padding-top: 1.25rem;
  padding-bottom: 2.1875rem;
  color: #666666;
}

/* 公司 */
.cen-mian-head-lcompany {
  display: flex;
  flex-flow: row;
  height: 3.125rem;
  align-items: center;
  margin-bottom: 1.125rem;
}

.cen-mian-head-lcompany > span:first-of-type {
  height: 1.125rem;
  font-size: 1.5rem;
  font-weight: 800;
  color: #111111;
  line-height: 1.125rem;
  margin-right: 2rem;
}

.cen-mian-head-lcompany > div:first-of-type {
  display: flex;
  flex-flow: row;
}

.cen-mian-head-lcompany > div:first-of-type > div {
  display: flex;
  flex-flow: row;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  padding: 2px 0.4375rem;
  font-weight: 400;
}
.cen-mian-head-lcompany > div:last-of-type > div {
  display: flex;
  flex-flow: row;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  padding: 2px 0.4375rem;
  font-weight: 400;
}

.cen-mian-head-lcompany > div:first-of-type > div:first-of-type {
  border-radius: 2px;
  border: 1px solid #4286e3;
  color: #4286e3;
}

.cen-mian-head-lcompany > div:first-of-type > div:nth-child(2) {
  border-radius: 2px;
  border: 1px solid #f89941;
  color: #f89941;
}

.cen-mian-head-lcompany > div:first-of-type > div:last-of-type {
  border-radius: 4px;
  border: 1px solid #ef5f37;
  color: #ef5f37;
}

.cen-mian-head-lcompany > div:last-of-type > div:last-of-type {
  border-radius: 0.25rem;
  border: 1px solid #f89941;
  color: #f89941;
}
// 联系方式
.cen-l-contact-details {
  display: flex;
  flex-flow: row;
}

.cen-l-contact-details > div:first-of-type {
  margin-right: 8rem;
}

.cen-l-contact-details > div:first-of-type > div {
  margin-top: 1rem;
}

.cen-l-contact-details > div:last-of-type > div {
  margin-top: 1rem;
}
.collect-btn {
  display: flex;
  cursor: pointer;
  & > div {
    border: 1px solid #fff;
    font-size: 12px;
    text-align: center;
    &:first-of-type {
      height: 32px;
      width: 32px;
      line-height: 32px;
      font-size: 16px;
    }
    &:nth-of-type(2) {
      height: 30px;
      width: 32px;
      padding: 1px 4px;
      line-height: 14px;
    }
  }
  &.collect-btn-on {
    & > div:first-of-type {
      background: #3e68ff;
      border-color: #3e68ff;
      color: #fff;
    }
    & > div:nth-of-type(2) {
      border-color: #3e68ff;
      color: #3e68ff;
    }
  }
  &.collect-btn-off {
    & > div:first-of-type {
      border-color: #999999;
      color: #999;
    }
    & > div:nth-of-type(2) {
      background: #999;
      border-color: #999;
      color: #fff;
    }
  }
}
.icon-collect {
  vertical-align: middle;
}
.patent-img {
  width: 100%;
}
.divider-line {
  border-bottom: 1px solid #f6f6f6;
  margin-top: 8px;
  margin-bottom: 16px;
}
</style>
