<template>
  <div class="product-information" v-if="detailsInfo">
    <div class="product-information-cen" v-for="(item,index) in detailsInfo" :key="index">
      <!-- 信息 -->
      <div class="product-cen-top">
        <div v-if="item.product_image&&item.product_image!=='-'" class="product-cen-img">
          <img :src="item.product_image" />
        </div>
        <div v-else class="product-cen-img">
          <img src="@/assets/images/detail/que.png" />
        </div>
        <div>
          <div>
            <div>{{$t('产品名称')}}：</div>
            <div>{{item.name}}</div>
          </div>
          <div>
            <div>{{$t('包装')}}：</div>
            <div>{{item.package}}</div>
          </div>
          <div>
            <div>容量：</div>
            <div>{{item.capacity}}</div>
          </div>
          <div>
            <div>{{$t('储藏方式')}}：</div>
            <div>{{item.storage_method}}</div>
          </div>
          <div>
            <div>{{$t('产品类别')}}：</div>
            <div>{{item.product_category}}</div>
          </div>
          <div>
            <div>{{$t('获奖内容')}}：</div>
            <div>{{item.awarded_content}}</div>
          </div>
          <div>
            <div>{{$t('参考售价')}}：</div>
            <div>{{item.reference_price}}</div>
          </div>
          <div>
            <div>{{$t('销售通路')}}：</div>
            <div>{{item.sales_channel}}</div>
          </div>
        </div>
      </div>
      <!-- 描述 -->
      <div class="product-cen-bot">
        <div>{{$t('产品介绍')}}</div>
        <div>{{item.product_introduction}}</div>
      </div>
    </div>
  </div>
  <div v-else class>{{$t('暂时没有相关数据')}}！</div>
</template>

<script>
export default {
  props: ['detailsInfo', 'displayInfo'],
  data() {
    return {
      // 盒子默认重第几个开始,循环几个
      divItemBegin: 0,
      divItemTheEnd: 2,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.product-information {
}

.product-information-cen {
  color: #333333;
  margin-bottom: 16px;
  border: 1px solid #9accfe;
  box-shadow: 0px 2px 4px 0px #f4f6f8, 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 1rem 1rem 1.5rem 0.625rem;
}

.product-cen-top {
  display: flex;
  flex-flow: row;
  height: 25rem;
}

.product-cen-top > div:first-of-type {
  margin-right: 1.625rem;
}

.product-cen-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 385px;
  width: 385px;
  border: 1px solid #eeeeee;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.product-cen-top > div:last-of-type {
  display: flex;
  flex-flow: column;
  flex: 1;
}

.product-cen-top > div:last-of-type > div {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  height: 2.4375rem;
  border-bottom: 1px solid #f9f9f9;
  padding-bottom: 0.5rem;
}

.product-cen-top > div:last-of-type > div:first-of-type {
  height: 1.875rem;
  align-items: flex-start;
  padding-bottom: 0rem;
}

.product-cen-top > div:last-of-type > div > div:first-of-type {
  width: 5.5rem;
  color: #999999;
}

/* 产品信息底部 */
.product-cen-bot > div:first-of-type {
  font-size: 1rem;
  color: #333333;
  margin-bottom: 0.5rem;
}

.product-cen-bot > div:last-of-type {
  color: #999999;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
</style>
