<!--
 * @Author: wyq
 * @Date: 2021-06-02 16:02:12
 * @LastEditTime: 2021-06-02 17:10:46
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\details\infoContainer.vue
-->
<template>
  <div class="info-container">
    <div class="info-container-header">
      <div class="title">{{title}}</div>
      <div v-if="$slots.subTitle" class="sub-title">
        <slot name="subTitle"></slot>
      </div>
    </div>
    <div class="info-container-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: '',
  },
}
</script>
<style lang="scss" scoped>
.info-container {
  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px 0 16px;
    padding: 0 0 10px 12px;
    font-size: 18px;
    font-weight: 800;
    color: #333333;
    border-bottom: 1px solid #f6f6f6;

    &::before {
      position: absolute;
      left: 0;
      top: 4px;
      width: 4px;
      height: 17px;
      background: #3e68ff;
      content: '';
    }
  }
  &-body {
    padding: 16px;
  }
}
</style>