<!--
 * @Author: wyq
 * @Date: 2021-06-02 16:24:51
 * @LastEditTime: 2021-06-02 16:47:42
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\details\infoItem.vue
-->
<template>
  <div class="info-item" :style="'width:'+width+'px'">
    <div class="label" :style="'width:'+labelWidth+'px'">{{label}}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 380,
    },
    labelWidth: {
      type: Number,
      default: 96,
    },
    label: String,
  },
}
</script>
<style lang="scss" scoped>
.info-item {
  display: inline-block;
  div {
    display: inline-block;
  }
  .label {
    height: 16px;
    margin-right: 10px;
    line-height: 16px;
    color: #666666;
    border-right: 2px solid #3e68ff;
  }
}
</style>